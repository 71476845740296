<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap">
      <div class="px-4 w-full">
        <BigTitle title="Settings" class="mb-11 pt-40"/>
        <RequestFreeAccess />
      </div>
    </div>
  </div>
</template>

<script>
import RequestFreeAccess from '@/components/settings/RequestFreeAccess'
import BigTitle from '@/components/BigTitle'

export default {
  name: 'Settings-Billing-Plans-Request',
  components: { 
    RequestFreeAccess,
    BigTitle 
  },
}
</script>

<style>

</style>